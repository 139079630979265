import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from "react-select";
import { API_ROOT, GOOGLE_API_KEY } from '@config';
import date from '@utilities/date';
import { useDispatch, useSelector } from 'react-redux';
import http from '@utilities/Http';
import TextField from '@components/TextField/TextField';
import FileField from '@components/FileField/FileField';
import SelectField from '@components/SelectField/SelectField';
import CheckboxField from '@components/CheckboxField/CheckboxField';
import FireDepartmentField from '@components/FireDepartmentField/FireDepartmentField';
import FieldRow from '@components/Field/FieldRow';
import FieldColumns from '@components/Field/FieldColumns';
import FieldColumn from '@components/Field/FieldColumn';
import FormValidationModal from '@components/FormValidationModal/FormValidationModal';
import FieldGroup from '@components/Field/FieldGroup';
import Button from '@components/Button/Button';
import styles from '../../Order.module.scss';
import { compareOrderAddress, getAddressString, validateAddressLengths } from '@utilities/address';
import { addAddress } from '@store/reducers/addresses';
import { isFinalized } from '@utilities/orders';
import classNames from 'classnames';
import Tooltip from '@components/Tooltip/Tooltip';
import TextAreaField from '@components/TextAreaField/TextAreaField';
import GooglePlacesAutocomplete from "@components/GooglePlacesAutocomplete/GooglePlacesAutocomplete";
import {useErrorAlert} from "@hooks/useErrorAlert";

const documentMimeTypes = [
	'.pdf',
	'.docx',
	'.doc',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
];

const additionalDocumentMimeTypes = [
	'.pdf',
	'.docx',
	'.doc',
	'application/msword',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/pdf',
	'.png',
	'.jpeg',
	'.jpg',
	'text/csv',
	'.xlsx',
	'.csv',
	'.xls'
];



const OrderForm = ({
	order,
	errors,
	setOrder = () => {},
	setErrors = () => {},
	handleFieldChange = () => {},
	handleDocumentUpload = () => {},
	validationModalActive = false,
	setValidationModalActive = false,
	shippingFields = {},
	selectedAddress = null,
	setSelectedAddress = () => {},
	shippingCredentials = {},
	isEditable = false,
	isSubmittingTCM = false,
	handleSaveOrder = () => {},
}) => {
	const user = useSelector((state) => state.auth.user);
	const isCSRUser =
		user?.permissions?.includes('csr_access');
	const dispatch = useDispatch();
	// fetching / submitting flags
	const [isSavingAddress, setIsSavingAddress] = useState(false);
	const [manualDepartment, setManualDepartment] = useState(false);
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [fireDeptContact,setFireDeptContact] = useState(order.fire_department_contact_email || '');
	// addresses
	const { addresses } = useSelector((state) => state.addresses);
	const [tcmShipTos, setTcmShipTos] = useState([]);

	const { alertCodes } = useSelector((state) => ({
		alertCodes: state.staticData.alertCodes,
	}));
	const countries = useSelector((state) => state.staticData.countries);
	const fireDepartmentCountries = useSelector((state) => state.staticData.fireDepartmentCountries);

	const [, setErrorAlert] = useErrorAlert();


	/**
	 * Static Data
	 */

	const { shippingOptions, orderSettings, states } = useSelector(
		(state) => state.staticData,
	);


	const filteredStates = useMemo(() => {
		let filteredStates = [...states];
		if (order?.order_ship_to_country){
			let selectedCountry = countries.find(country => country.country_code === order?.order_ship_to_country) || {country_code: 'US'}
			return states.filter(state => state.country_code === selectedCountry.country_code)
		}
		return filteredStates;
	}, [order?.order_ship_to_country, states])

	const checkAlertCode = useCallback(
		(alertCodeId) => {
			return order?.alert_codes?.includes(alertCodeId);
		},
	[order?.alert_codes]);

	const handleAlertCodeChange = (e) => {
		if(e.target.value){
			handleFieldChange({
				target: { name: 'alert_codes', value: [...order.alert_codes, e.target.name] },
			});
			return;
		}

		handleFieldChange({
			target: { name: 'alert_codes', value: order.alert_codes.filter(
					(alertCode) => alertCode != e.target.name,
				)
			},
		});
	}

	/**
	 * Handle Save Address
	 */

	const handleSaveAddress = useCallback(async () => {
		// validate shipping address
		const shippingErrors = Object.keys(shippingFields).reduce(
			(shippingErrors, key) => {
				if (key !== 'order_ship_to_attention' && !order[key]) {
					shippingErrors[key] = shippingFields[key];
				}

				return shippingErrors;
			},
			{},
		);

		setErrors((errors) => ({ ...errors, ...shippingErrors }));

		if (Object.keys(shippingErrors).length) {
			return;
		}

		const addressLengthErrors = validateAddressLengths(order);
		if (addressLengthErrors.length > 0) {
			setErrorAlert({
				errorTitle: 'Address Error',
				errorMessage: addressLengthErrors.join(''),
			});
			return;
		}

		try {
			setIsSavingAddress(true);

			// find selected state
			const state = states.find(
				(state) => state.state_code === order.order_ship_to_state,
			);

			const {
				data: { address },
			} = await http().post(`${API_ROOT}/users/addresses`, {
				address: {
					address_street_1: order.order_ship_to_street,
					address_number: order.order_ship_to_number,
					address_attention: order.order_ship_to_attention,
					address_name: order.order_ship_to_name,
					address_city: order.order_ship_to_city,
					state_id: state.state_id,
					address_zip: order.order_ship_to_zip,
				},
			});

			// add to saved addresses
			dispatch(addAddress(address));

			// set as selected
			setSelectedAddress(address);
		} catch (error) {
			console.log('error');
		} finally {
			setIsSavingAddress(false);
		}
	}, [
		order,
		dispatch,
		states,
		setErrors,
		setSelectedAddress,
		shippingFields,
	]);

	/**
	 * Handle Select Saved Address
	 */

	const handleSelectAddress = useCallback(
		(option) => {
			const selected = addresses.find(
				(address) => address.user_address_id === option.value,
			);

			if (!selected) {
				return;
			}

			setSelectedAddress(option.value);

			// write to address fields
			setOrder((order) => {
				const updatedOrder = {
					...order,
					order_ship_to_attention: selected.address_attention,
					order_ship_to_name: selected.address_name,
					order_ship_to_street:
						selected.address_street_1 +
						(selected.address_street_2 || ''),
					order_ship_to_number: selected.address_number,
					order_ship_to_city: selected.address_city,
					order_ship_to_state: selected.state_code,
					order_ship_to_zip: selected.address_zip,
				};

				handleSaveOrder(updatedOrder);

				return updatedOrder;
			});

			// unset shipping field errors
			setErrors((errors) => {
				const shippingErrors = Object.keys(shippingFields).reduce(
					(shippingErrors, field) => {
						shippingErrors[field] = null;
						return shippingErrors;
					},
					{},
				);

				return { ...errors, ...shippingErrors };
			});
		},
		[
			addresses,
			order,
			setErrors,
			setOrder,
			setSelectedAddress,
			shippingFields,
		],
	);

	/**
	 * Handle selecting TCM Ship-to
	 */

	const getTcmShipToById = useCallback(
		(id) => {
			return tcmShipTos?.find(
				(tcmShipTo) => parseInt(tcmShipTo.SEQ_SHIPTO) === parseInt(id),
			);
		},
		[tcmShipTos],
	);

	const handleSelectTcmShipTo = useCallback(
		(tcmShipTo) => {
			if (tcmShipTo) {
				handleFieldChange({
					target: {
						name: 'order_tcm_ship_to_sequence_id',
						value: tcmShipTo.SEQ_SHIPTO,
					},
				});
				handleFieldChange({
					target: {
						name: 'order_tcm_sales_rep_1_id',
						value: tcmShipTo.ID_SLSREP,
					},
				});
			} else {
				handleFieldChange({
					target: {
						name: 'order_tcm_ship_to_sequence_id',
						value: null,
					},
				});
				handleFieldChange({
					target: {
						name: 'order_tcm_sales_rep_1_id',
						value: null,
					},
				});
			}
		},
		[handleFieldChange],
	);

	const handleSelectState = (stateName) => {
		const foundState = states.find((state) => state.state_name === stateName);

		if (foundState) {
			handleFieldChange({
				target: {
					name: 'order_ship_to_state',
					value: foundState.state_code
				}
			});
		}
	}

	/**
	 * Saved Address Options
	 */

	const savedAddressOptions = useMemo(() => {
		return addresses.map((address) => {
			return {
				label: getAddressString(address),
				value: address.user_address_id,
			};
		});
	}, [addresses]);

	/**
	 * Find selected address
	 */

	useEffect(() => {
		if (!order || !addresses) {
			return;
		}

		const savedAddress = addresses.find((address) =>
			compareOrderAddress(order, address),
		);

		if (savedAddress) {
			setSelectedAddress(savedAddress.user_address_id);
		}
	}, [addresses, order, setSelectedAddress]);

	/**
	 * Pull in organization TCM Ship-tos
	 */
	useEffect(() => {
		if (isCSRUser) {
			http()
				.get(
					`${API_ROOT}/organizations/tcm-ship-tos/${order.organization_id}`,
				)
				.then((response) => {
					setTcmShipTos(response.data.tcmShipTos);
				});
		}
	}, [isCSRUser, order.organization_id]);

	/**
	 * Show/Hide shipping account field
	 */

	const showShippingAccount = useMemo(() => {
		const selectedShipping = shippingOptions?.find(
			(option) => option.shipping_option_id === order?.shipping_option_id,
		);

		return !!selectedShipping?.shipping_option_requires_account;
	}, [order, shippingOptions]);

	const showShippingInstructions = useMemo(() => {
		const selectedShipping = shippingOptions?.find(
			(option) => option.shipping_option_id === order?.shipping_option_id,
		);
		return selectedShipping?.shipping_option_requires_instructions == 1;
	}, [order, shippingOptions]);

	return (
		<>
			{isCSRUser && (
				<FieldRow>
					<TextField
						label="Distributor"
						value={order.organization_name}
						disabled={true}
					/>
					<TextField
						label="Distributor TCM Sold-To-ID"
						value={order.tcm_customer_sold_to_id}
						disabled={true}
					/>
					<TextField
						label="Created By"
						value={order.user_display_name}
						disabled={true}
					/>
				</FieldRow>
			)}
			<FieldRow
				className={classNames({
					[styles.tcmFieldRow]: isCSRUser,
					[styles.fieldRowColumn]:
						order.order_manual_department_flag === 1,
				})}>
				<TextField
					label="Order Name"
					value={order.order_name}
					name="order_name"
					error={errors.order_name}
					onChange={handleFieldChange}
					disabled={!isEditable}
					className={classNames({
						[styles.nameFieldRow]: true,
					})}
				/>
				{order.order_stock_flag === 1 && (
					<TextField
						className={classNames({
							[styles.fireDepartmentField]: true,
							[styles.hideField]: order.order_stock_flag !== 1,
						})}
						label={
							<div className={styles.stockOrderToggle}>
								Gear Use
								<span
									className={styles.noFindLink}
									onClick={(e) => {
										e.preventDefault();
										if (isEditable) {

											handleFieldChange({
												target: {
													name: 'order_manual_department_flag',
													value: 1,
												},
											});
										}
									}}>
									Can&apos;t Find Department?
								</span>
								<div
									className={styles.stockOrderSwitch}
									onMouseEnter={() => setTooltipVisible(true)}
									onMouseLeave={() =>
										setTooltipVisible(false)
									}>
									<CheckboxField
										label="Not For Fire Department"
										value={order.order_stock_flag}
										name="order_stock_flag"
										onChange={handleFieldChange}
										className={styles.stockOrderSwitch}
										disabled={!isEditable}
									/>
									<Tooltip
										active={tooltipVisible}
										className={styles.stockOrderTooltip}>
										Use text field to enter gear use
									</Tooltip>
								</div>
							</div>
						}
						name="order_stock_order_input"
						value={order.order_stock_order_input || ''}
						error={errors.order_stock_order_input}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
				)}
				{order.order_stock_flag !== 1 &&
					order.order_manual_department_flag !== 1 && (
						<>
							<FireDepartmentField
								label={
									<div className={styles.stockOrderToggle}>
										Fire Department Zip / Name
										<span
											className={styles.noFindLink}
											onClick={(e) => {
												e.preventDefault();
												if (isEditable) {
													handleFieldChange({
														target: {
															name: 'order_manual_department_flag',
															value: 1,
														},
													});
												}
											}}>
											Can&apos;t Find Department?
										</span>
										<div
											className={styles.stockOrderSwitch}
											onMouseEnter={() =>
												setTooltipVisible(true)
											}
											onMouseLeave={() =>
												setTooltipVisible(false)
											}>
											<CheckboxField
												label="Not For Fire Department"
												value={order.order_stock_flag}
												name="order_stock_flag"
												onChange={handleFieldChange}
												className={styles.stockOrderSwitch}
												disabled={!isEditable}
											/>
											<Tooltip
												active={tooltipVisible}
												className={
													styles.stockOrderTooltip
												}>
												Use text field to enter gear use
											</Tooltip>
										</div>
									</div>
								}
								value={order.fire_department_id}
								name="fire_department_id"
								onChange={handleFieldChange}
								disabled={!isEditable}
								defaultValues={[
									order.fire_department_zip,
									order.fire_department_name,
									order.fire_department_country,
								]}
								error={errors.fire_department_id}
								className={classNames({
									[styles.fireDepartmentField]: true,
									[styles.hideField]: order.order_stock_flag,
								})}
								countries={fireDepartmentCountries}
								isCSRUser={isCSRUser}
							>
								{isCSRUser && order.fire_department_id && (
									<FieldRow className={classNames({[styles.orderFireDepartmentInformation]: true})}>
										<p>{order.fire_department_street}</p>
										<p>{order.fire_department_city}</p>
										<p>{order.fire_department_state}</p>
									</FieldRow>
								)}
							</FireDepartmentField>
						</>
					)}
				{order.order_manual_department_flag === 1 && (
					<FieldRow
						className={classNames({
							[styles.verticalRow]: true,
						})}>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
								[styles.hideField]:
									order.order_stock_flag === 1,
							})}>
							<TextField
								className={classNames({
									[styles.fireDepartmentField]: true,
								})}
								label={
									<div className={styles.stockOrderToggle}>
										Fire Department Name*
										<span
											className={styles.noFindLink}
											onClick={(e) => {
												e.preventDefault();

												handleFieldChange({
													target: {
														name: 'order_manual_department_flag',
														value: 0,
													},
												});
											}}>
											Can&apos;t Find Department?
										</span>
										<div>
											<div
												className={
													styles.stockOrderSwitch
												}
												onMouseEnter={() =>
													setTooltipVisible(true)
												}
												onMouseLeave={() =>
													setTooltipVisible(false)
												}>
												<CheckboxField
													label="Not For Fire Department"
													value={
														order.order_stock_flag ===
														1
													}
													name="order_stock_flag"
													onChange={() => {
														handleFieldChange({
															target: {
																name: 'order_stock_flag',
																value: 1,
															},
														});
														handleFieldChange({
															target: {
																name: 'order_manual_department_flag',
																value: 0,
															},
														});
													}}
													className={
														styles.stockOrderSwitch
													}
													disabled={!isEditable}
												/>
												<Tooltip
													active={tooltipVisible}
													className={
														styles.stockOrderTooltip
													}>
													Use text field to enter gear
													use
												</Tooltip>
											</div>
										</div>
									</div>
								}
								name="fire_department_name_manual"
								value={order.fire_department_name_manual || ''}
								error={errors.fire_department_name_manual}
								onChange={handleFieldChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department Address'}
								name="fire_department_address"
								value={order.fire_department_address || ''}
								error={errors.fire_department_address}
								onChange={handleFieldChange}
							/>
							<TextField
								label={'Fire Department City'}
								name="fire_department_city"
								value={order.fire_department_city || ''}
								error={errors.fire_department_city}
								onChange={handleFieldChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department State / Province / Region'}
								name="fire_department_state"
								value={order.fire_department_state || ''}
								error={errors.fire_department_state}
								onChange={handleFieldChange}
							/>
							<TextField
								label={'Fire Department Zip / Postal Code'}
								name="fire_department_zip_manual"
								value={order.fire_department_zip_manual || ''}
								error={errors.fire_department_zip_manual}
								onChange={handleFieldChange}
							/>
						</FieldRow>
						<FieldRow
							className={classNames({
								[styles.fireDepartmentField]: true,
							})}>
							<TextField
								label={'Fire Department County'}
								name="fire_department_county"
								value={order.fire_department_county || ''}
								error={errors.fire_department_county}
								onChange={handleFieldChange}
							/>
							<SelectField
                                    label="Fire Department Country"
                                        placeholder="Country"
                                        value={order.fire_department_country || ''}
                                        name="fire_department_country"
                                        onChange={handleFieldChange}
                                        options={countries}
                                        labelField="country_name"
                                        valueField="country_code"
                                        className={classNames({ [styles.countryField]: true })}
                                        inputClassName={classNames({[styles.fullHeight]: true})}
                                        menuClassName={classNames({[styles.selectMenu]: true})}
                                    />
						</FieldRow>
					</FieldRow>
				)}
			</FieldRow>
			{((order.fire_department_id || order.order_manual_department_flag == 1) && order.order_stock_flag !== 1) &&
						<>
						<FieldRow
						className={styles.borderTop}>
									<TextField
										label={'Fire Department Contact - First Name'}
										name="fire_department_contact_first"
										value={order.fire_department_contact_first || ''}
										error={errors.fire_department_contact_first}
										onChange={handleFieldChange}
										disabled={isSubmittingTCM}
									/>
									<TextField
										label={'Fire Department Contact - Last Name'}
										name="fire_department_contact_last"
										value={order.fire_department_contact_last || ''}
										error={errors.fire_department_contact_last}
										onChange={handleFieldChange}
										disabled={isSubmittingTCM}
									/>
									<TextField
										label={'Fire Department Contact - Title'}
										name="fire_department_contact_title"
										value={order.fire_department_contact_title || ''}
										error={errors.fire_department_contact_title}
										onChange={handleFieldChange}
										disabled={isSubmittingTCM}
									/>
									<TextField
										label={'Fire Department Contact - Email'}
										name="fire_department_contact_email"
										value={fireDeptContact}
										error={errors.fire_department_contact_email}
										onChange={(e)=>{setFireDeptContact(e.target.value)}}
										onBlur={handleFieldChange}
										disabled={isSubmittingTCM}
									/>

						</FieldRow>
						</>
						}
			<FieldRow className={styles.detailsRowReadOnly} wrap={true}>
				<TextField
					label="FireWriter Order #"
					value={order.order_id}
					compact
					onChange={handleFieldChange}
					name="order_id"
					readonly={true}
				/>
				<TextField
					label="Fire-Dex Order #"
					value={order.order_fire_dex_number || ''}
					compact
					onChange={handleFieldChange}
					name="order_fire_dex_number"
					readonly={true}
				/>
				<TextField
					label="Status"
					value={
						order.order_status || 'In Progress'
					}
					name="order_status"
					onChange={handleFieldChange}
					readonly
				/>
				{order.order_tracking_links.length > 0 && (
					<div className={styles.customFieldContainer}>
						<label>Tracking</label>
						{order.order_tracking_links?.map( (tracking_code) => {
							return (
								<a
									key={tracking_code.order_tracking_code_id}
									href={tracking_code.tracking_link}
									target={'_blank'}
									rel="noreferrer">
									{tracking_code.order_tracking_code}
								</a>
							);
						})}
					</div>
				)}
				<TextField
					label="Submitted Date"
					value={
						order.order_submitted_timestamp === null
							? ''
							: date(order.order_submitted_timestamp).format(
									'M/DD/YY',
							  )
					}
					readonly
				/>
				<TextField
					label="# of Quotes"
					value={order.quotes.filter((quote) => quote.sibling_quote_id === null).length || 0}
					readonly
					className={styles.itemsCount}
				/>
			</FieldRow>
			<FieldColumns>
				<FieldColumn justify>
					<TextField
						compact
						label="PO #"
						placeholder="Enter #"
						value={order.order_po_number || ''}
						name="order_po_number"
						onChange={handleFieldChange}
						error={errors.order_po_number}
						disabled={!isEditable}
					/>
					<FileField
						compact
						accept={documentMimeTypes.join(', ')}
						label="PO Document"
						placeholder="Upload Doc +"
						disabled={
							!isEditable ||
							(isCSRUser && order.user_id !== user.user_id)
						}
						error={errors.orderDocuments}
						name="orderDocuments"
						value={order.orderDocuments?.filter(
							(doc) => doc.order_document_type === 'PO',
						)}
						labelField="order_document_file"
						onFileSelect={(files) => {
							setErrors((errors) => ({
								...errors,
								orderDocuments: null,
							}));
							handleDocumentUpload(files, 'PO');
						}}
						onChange={(e) => {
							handleFieldChange({
								target: {
									...e.target,
									value: [
										...order.orderDocuments.filter(
											(doc) =>
												doc.order_document_type !==
												'PO',
										),
										...e.target.value,
									],
								},
							});
						}}
						allowDelete={false}
					/>
					<FileField
						compact
						accept={additionalDocumentMimeTypes.join(', ')}
						label="Additional Documents"
						placeholder="Upload Docs +"
						multiple
						disabled={!isEditable}
						name="orderDocuments"
						value={order.orderDocuments?.filter(
							(doc) => doc.order_document_type === 'Additional',
						)}
						labelField="order_document_file"
						onFileSelect={(files) =>
							handleDocumentUpload(files, 'Additional')
						}
						onChange={(e) => {
							handleFieldChange({
								target: {
									...e.target,
									value: [
										...order.orderDocuments.filter(
											(doc) =>
												doc.order_document_type !==
												'Additional',
										),
										...e.target.value,
									],
								},
							});
						}}
					/>
				</FieldColumn>
				<FieldColumn>
					{isEditable &&
						!!savedAddressOptions.length &&
						(!isCSRUser || order.user_id === user.user_id) && (
							<SelectField
								compact
								label="Select Saved Address"
								placeholder="Saved Addresses"
								options={savedAddressOptions}
								value={selectedAddress}
								onSelect={handleSelectAddress}
								disabled={!isEditable}
								className={styles.savedAddressField}
							/>
						)}
					<GooglePlacesAutocomplete isEditable={isEditable} handleFieldChange={handleFieldChange} countries={countries.map((country) => country.country_code.toLowerCase())} handleSelectState={handleSelectState} />
					<TextField
						compact
						placeholder="Attn:"
						value={order.order_ship_to_attention || ''}
						name="order_ship_to_attention"
						error={errors.order_ship_to_attention}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
					<TextField
						compact
						placeholder="Company Name*"
						value={order.order_ship_to_name || ''}
						name="order_ship_to_name"
						error={errors.order_ship_to_name}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
					<TextField
						compact
						placeholder="Address 1*"
						value={order.order_ship_to_street || ''}
						name="order_ship_to_street"
						error={errors.order_ship_to_street}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
					<TextField
						compact
						placeholder="Suite/Apt #"
						value={order.order_ship_to_number || ''}
						name="order_ship_to_number"
						error={errors.order_ship_to_number}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
					<SelectField
							compact
							placeholder="Country*"
							value={order.order_ship_to_country || ''}
							name="order_ship_to_country"
							error={errors.order_ship_to_country}
							onChange={handleFieldChange}
							options={countries}
							labelField="country_name"
							valueField="country_code"
							disabled={!isEditable}
						/>
					<FieldGroup>
						<TextField
							compact
							placeholder="City*"
							value={order.order_ship_to_city || ''}
							name="order_ship_to_city"
							error={errors.order_ship_to_city}
							onChange={handleFieldChange}
							className={styles.cityField}
							disabled={!isEditable}
						/>
						<SelectField
							compact
							placeholder="State*"
							value={order.order_ship_to_state || ''}
							name="order_ship_to_state"
							error={errors.order_ship_to_state}
							onChange={handleFieldChange}
							options={filteredStates}
							labelField="state_name"
							valueField="state_code"
							className={styles.stateField}
							disabled={!isEditable}
						/>
					</FieldGroup>

					<TextField
						compact
						placeholder="Zip Code*"
						value={order.order_ship_to_zip || ''}
						name="order_ship_to_zip"
						error={errors.order_ship_to_zip}
						onChange={handleFieldChange}
						disabled={!isEditable}
					/>
					{isEditable &&
						(!isCSRUser || order.user_id === user.user_id) && (
							<Button
								variant="solid"
								className={styles.saveAddressButton}
								onClick={handleSaveAddress}
								loading={isSavingAddress}
								disabled={!!selectedAddress}>
								Save for Later
							</Button>
						)}
				</FieldColumn>
				<FieldColumn className={styles.fieldColumnGrouped}>
					<div className={styles.fieldColumnGroup}>
						<SelectField
							compact
							label="Shipping"
							placeholder="Select Shipping"
							value={
								order.shipping_option_id ??
								shippingOptions?.find(
									(option) =>
										option.shipping_option_description ===
										'UPS GROUND',
								).shipping_option_id
							}
							name="shipping_option_id"
							onChange={handleFieldChange}
							onSelect={(option) => {
								// populate shipping account if has credentials
								if (
									shippingCredentials[
										option.shipping_option_id
									]
								) {
									handleFieldChange({
										target: {
											name: 'order_shipping_account',
											value: shippingCredentials[
												option.shipping_option_id
											],
										},
									});
									return;
								}

								// else unset shipping account number
								handleFieldChange({
									target: {
										name: 'order_shipping_account',
										value: '',
									},
								});
							}}
							options={shippingOptions}
							labelField="shipping_option_description"
							valueField="shipping_option_id"
							disabled={!isEditable}
						/>
						{!!showShippingAccount && (
							<TextField
								compact
								label="Shipping Account*"
								placeholder="Account Number"
								value={order.order_shipping_account || ''}
								name="order_shipping_account"
								error={errors.order_shipping_account}
								onChange={handleFieldChange}
								disabled={!isEditable}
							/>
						)}
						{showShippingInstructions &&

							<TextAreaField
							label="Special shipping instructions*"
							className={styles.instructionsField}
							placeholder="Instructions"
							value={order.order_shipping_instructions || ''}
							name="order_shipping_instructions"
							onChange={handleFieldChange}
							disabled={!isEditable} />

						}
					</div>
					<div className={styles.fieldColumnGroup}>
						<label>Order Alert Code</label>
						{alertCodes.map((alertCode) => {

							if(alertCode.order_alert_code_csr == 1 && !isCSRUser){
								return null;
							}
							if(alertCode.order_alert_code_setting_field && !orderSettings[alertCode.order_alert_code_setting_field]){
								return null;
							}
							return (
								<CheckboxField
									key={alertCode.order_alert_code_id}
									label={alertCode.order_alert_code_title}
									value={checkAlertCode(alertCode.order_alert_code_id)}
									name={alertCode.order_alert_code_id}
									onChange={handleAlertCodeChange}
									disabled={
										alertCode.order_alert_code_hubspot_company ||
										(!isEditable && !isCSRUser) ||
										isFinalized(order) ||
										isSubmittingTCM
									}
								/>
							);
							})
						}

					</div>
				</FieldColumn>
			</FieldColumns>
			{isCSRUser && (
				<>
					<FieldRow className={styles.tcmFieldRow}>
						{!!tcmShipTos?.length && (
							<div className={styles.tcmShipToContainer} >
								<label >TCM Ship-to</label>
								<Select
									compact
									label="TCM Ship-to"
									value={getTcmShipToById(
										order.order_tcm_ship_to_sequence_id,
									)}
									name="tcm_ship_to"
									onChange={handleSelectTcmShipTo}
									options={[
										...[{label: 'Select TCM Ship-to', SEQ_SHIPTO: null}],
										...tcmShipTos
									]}
									isOptionSelected={(tcmShipTo) => tcmShipTo.SEQ_SHIPTO === order.order_tcm_ship_to_sequence_id}
									getOptionLabel={(tcmShipTo) => {
										return tcmShipTo.label ? tcmShipTo.label : `${tcmShipTo.SEQ_SHIPTO.toString().padStart(
											4,
											'0',
										)}: ${tcmShipTo.NAME_CUST}`;
									}}
									getOptionValue={(tcmShipTo) => tcmShipTo.SEQ_SHIPTO}
									disabled={(isFinalized(order) && order.order_status !== 'Cancelled') || isSubmittingTCM}
								/>
							</div>
						)}
						<TextField
							compact
							label="TCM Sales Rep ID"
							name="order_tcm_sales_rep_1_id"
							value={order.order_tcm_sales_rep_1_id || ''}
							disabled={true}
						/>
						<TextField
							compact
							label="TCM Territory Id"
							name="order_tcm_sales_rep_1_id"
							value={
								tcmShipTos?.find(
									(shipTo) =>
										parseInt(
											order.order_tcm_ship_to_sequence_id,
										) === shipTo.SEQ_SHIPTO,
								)
									? tcmShipTos?.find(
											(shipTo) =>
												parseInt(
													order.order_tcm_ship_to_sequence_id,
												) === shipTo.SEQ_SHIPTO,
									  )?.ID_TERR
									: ''
							}
							disabled={true}
						/>
					</FieldRow>
					<FieldRow style={{ marginTop: 20 }}>
						<TextAreaField
							label="TCM Order Header Notes"
							name="order_notes"
							value={order.order_notes || ''}
							onChange={handleFieldChange}
							disabled={(isFinalized(order) && order.order_status !== 'Cancelled') || isSubmittingTCM}
							inputClassName={styles.textarea}
						/>
					</FieldRow>
					{!isCSRUser && (
						<>
							<FieldRow className={styles.tcmFieldRow}>
								<TextField
									compact
									label="TCM Collect/Prepaid Code"
									name="order_tcm_collect_prepaid_code"
									value={
										order.order_tcm_collect_prepaid_code ||
										''
									}
									error={
										errors.order_tcm_collect_prepaid_code
									}
									onChange={handleFieldChange}
									disabled={isFinalized(order)}
								/>
								<TextField
									compact
									label="TCM Collect/Prepaid Description"
									name="order_tcm_collect_prepaid_description"
									value={
										order.order_tcm_collect_prepaid_description ||
										''
									}
									error={
										errors.order_tcm_collect_prepaid_description
									}
									onChange={handleFieldChange}
									disabled={isFinalized(order)}
								/>
							</FieldRow>
						</>
					)}
				</>
			)}
			<FormValidationModal
				active={validationModalActive}
				onClose={() => setValidationModalActive(false)}
				errors={errors}
			/>
		</>
	);
};

export default OrderForm;
