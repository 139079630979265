export function getAddressString(address = {}) {
	const segments = [];

	segments.push(
		address.address_attention ? 'Attn: ' + address.address_attention : '',
		address.address_name
			? (address.address_attention ? ' - ' : '') +
					address.address_name +
					': '
			: '',
		address.address_street_1 || '',
		address.address_street_2 || '',
		address.address_city ? address.address_city + ',' : '',
		address.state_name,
		address.address_zip,
	);

	return segments.join(' ');
}

export function compareOrderAddress(orderAddress = {}, dbAddress = {}) {
	const dbFields = [
		'address_attention',
		'address_name',
		'address_street_1',
		'address_number',
		'address_city',
		'state_code',
		'address_zip',
	];

	const orderFields = [
		'order_ship_to_attention',
		'order_ship_to_name',
		'order_ship_to_street',
		'order_ship_to_number',
		'order_ship_to_city',
		'order_ship_to_state',
		'order_ship_to_zip',
	];

	let isEqual = true;

	orderFields.forEach((orderField, i) => {
		if (orderAddress[orderField] !== dbAddress[dbFields[i]]) {
			isEqual = false;
		}
	});

	return isEqual;
}

export function validateAddressLengths(order){
	let errors = [];
	const combinedAddress = order['order_ship_to_city'] + ', ' + order['order_ship_to_state'] + ' ' + order['order_ship_to_zip'];
	if(order.order_ship_to_name && order.order_ship_to_name.length > 30) {
		errors.push('<p><br/>Ship to name exceeds 30 characters.<br/>Please shorten name by at least '+(order.order_ship_to_name.length - 30)+' characters:<br/><br/>'+(order.order_ship_to_name)+'</p>');
	}
	if(order.order_ship_to_attention && order.order_ship_to_attention.length > 30) {
		errors.push('<p><br/>Ship to Attention exceeds 30 characters.<br/>Please shorten attention by at least '+(order.order_ship_to_attention.length - 30)+' characters:<br/><br/>'+(order.order_ship_to_attention)+'<br/></p>');
	}
	if(order.order_ship_to_street && order.order_ship_to_street.length > 30) {
		errors.push('<p><br/>Address Street exceeds 30 characters.<br/>Please shorten street by at least '+(order.order_ship_to_street.length - 30)+' characters:<br/><br/>'+(order.order_ship_to_street)+'<br/></p>');
	}
	if(order.order_ship_to_number && order.order_ship_to_number.length > 30) {
		errors.push('<p><br/>Address Street Number exceeds 30 characters.<br/>Please shorten street number by at least '+(order.order_ship_to_number.length - 30)+' characters:<br/><br/>'+(order.order_ship_to_number)+'<br/></p>');
	}
	if(order.order_ship_to_city && order.order_ship_to_city.length > 30) {
		errors.push('<p><br/>Ship To City exceeds 30 characters.<br/>Please shorten city by at least '+(order.order_ship_to_city.length - 30)+' characters:<br/><br/>'+(order.order_ship_to_city)+'<br/></p>');
	}
	if(combinedAddress.length > 30) {
		errors.push('<p><br/>Combined City, State and Zipcode exceed 30 characters.<br/>Please shorten address by at least '+(combinedAddress.length - 30)+' characters:<br/><br/>'+(combinedAddress)+'</p>');
	}

	return errors;
}