import React from 'react';
import styles from '../Resources.module.scss';
import classNames from 'classnames';
export default function PriceList({prices, totalPages, curPage, setCurPage}) {
  return (
	<>
	<div className={classNames({[styles.priceList]: true})}>
		<div className={classNames({[styles.priceListHeader]: true, [styles.priceListGrid]: true})}>
			<span>Options</span>
			<span>Product Line</span>
			<span>Product</span>
			<span>Contract</span>
			<span>Price</span>
		</div>
	  {prices && prices.map((price, i) => (
	
		<div className={classNames({[styles.priceListRow]:true, [styles.priceListGrid]:true})} key={i + price.price_id}>
			<span>{price.price_product_option_values}</span>
			<span>{price.product_line_description}</span>
			<span>{price.price_product_title}</span>
			<span>{price.contract_title}</span>
			<span>{price.price_value}</span>
		</div>
	  ))}

	</div>
	<div className={classNames({[styles.pricePagination]: true})}>
		<button onClick={() => setCurPage(curPage - 1)} disabled={curPage === 1}>Previous</button>
		<span>{curPage}</span>
		<button onClick={() => setCurPage(curPage + 1)} disabled={curPage === totalPages}>Next</button>
	</div>
	</>
  );
}