import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import ResourceHeader from '@components/ResourceHeader/ResourceHeader';
import {Helmet} from 'react-helmet';
import ResourceLines from '@components/ResourceLines/ResourceLines';
import {useRouteMatch} from 'react-router-dom';
import Http from "@utilities/Http";
import {API_ROOT} from "@config";
import {download} from "@utilities/export";
import axios, {CancelToken} from "axios";

const Resources = ({location}) => {
    const [products, setProducts] = useState([]);
    const [productDownloads, setProductDownloads] = useState([]);
    const [userDownloadHistory, setUserDownloadHistory] = useState([]);
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedResourceIndexes, setSelectedResourceIndexes] = useState([]);
    const [search, setSearch] = useState('');
    const [fileType, setFileType] = useState('');
    const isFavorite = !!useRouteMatch({path: '/favorites', exact: false});
    const user = useSelector((state) => state.auth.user);
    const fetchDownloadHistoryTimeoutRef = useRef(null);
    const fetchProductDownloadRequestSource = useRef(null);
    const urlParams = new URLSearchParams(location.search);
    const [productDownloadIds, setProductDownloadIds] =  useState(urlParams.get('productDownloadIds') ? JSON.parse(urlParams.get('productDownloadIds'))  : []);
    const [productDownloadShareKeys, setProductDownloadShareKeys] = useState([]);
    const [productDownloadsHistory, setProductDownloadsHistory] = useState([]);
    const [getGeneral, setGetGeneral] = useState(false);

    const productDownloadsActive = useMemo(() => {
        return selectedProductIndex !== null || isFavorite || search || fileType || getGeneral || productDownloadIds.length;
    }, [getGeneral, isFavorite, fileType, productDownloadIds.length, search, selectedProductIndex]);

    const resources = useMemo(() => {
        return productDownloadsActive ? productDownloads : products;
    }, [productDownloadsActive, productDownloads, products]);

    const resourcesFiltered = useMemo(() => {
        return selectedFolder !== null ? resources.filter(resource => resource.product_product_line_product_download_folder === selectedFolder) : resources.filter(resource => !resource.product_product_line_product_download_folder);
    }, [resources, selectedFolder]);

    const folders = useMemo(() => {
        let out = [];
        let found = [];
        for (let resource of resources) {
            if (resource.product_product_line_product_download_folder && !found.includes(resource.product_product_line_product_download_folder)) {
                found.push(resource.product_product_line_product_download_folder);
                out.push(resource);
            }
        }
        return out;

    }, [resources]);

    const fileTypes = useMemo(() => {
        return productDownloads.reduce((fileTypes, productDownload) => {
            if(!fileTypes.includes(productDownload.product_download_file_extension)) {
                fileTypes.push(productDownload.product_download_file_extension);
            }

            return fileTypes;
        }, []).filter(fileType => fileType);
    }, [productDownloads]);

    const fetchProducts = useCallback(async () => {
        const {data} = await Http().get(`${API_ROOT}/product-downloads/get-download-products/`);

        return data.products;
    }, []);

    const fetchProductDownloads = useCallback(async (params) => {
        fetchProductDownloadRequestSource.current?.cancel();
        fetchProductDownloadRequestSource.current = CancelToken.source();

        const {data} = await Http().get(`${API_ROOT}/product-downloads/get-product-downloads/`, {
            params,
            cancelToken: fetchProductDownloadRequestSource.current?.token
        });

        return data.productDownloads;
    }, []);

    const fetchOneTimeToken = useCallback(async () => {
        const {data} = await Http().get(`${API_ROOT}/auth/generate-token/`);

        return data.token;
    }, []);

    const getUserDownloadHistory = useCallback(async () => {
        if (fetchDownloadHistoryTimeoutRef.current) {
            clearTimeout(fetchDownloadHistoryTimeoutRef.current);
            fetchDownloadHistoryTimeoutRef.current = null;
        }

        const {data} = await Http().get(`${API_ROOT}/product-downloads/get-user-download-history/`);

        setUserDownloadHistory(data.userDownloadHistory);

        const processingArchivesCount = data.userDownloadHistory.reduce((count, userDownload) => {
            if (userDownload.user_product_download_archive_status === 'processing') {
                count = count + 1;
            }
            return count;
        }, 0);

        if (processingArchivesCount > 0) {
            fetchDownloadHistoryTimeoutRef.current = setTimeout(() => {
                getUserDownloadHistory();
            }, 5000)
        }
    }, []);

    const generateProductDownloadArchive = useCallback(({productId = null, productLineId = null, forGeneral = false, folder = null}) => {
        if (fetchDownloadHistoryTimeoutRef.current) {
            clearTimeout(fetchDownloadHistoryTimeoutRef.current);
        }

        fetchDownloadHistoryTimeoutRef.current = setTimeout(() => {
            getUserDownloadHistory();
        }, 5000);

        Http().post(`${API_ROOT}/product-downloads/generate-product-download-archive`, {
            productDownloadIds: selectedResourceIndexes.map(index => resourcesFiltered[index].product_download_id),
            productId,
            productLineId,
            forGeneral: forGeneral ? '1' : '0',
            folder
        }).then(({data}) => {
            const productDownloadArchive = data.productDownloadArchive;
            download(`${API_ROOT}${productDownloadArchive.user_product_download_archive_file_uri}`);
        });
    }, [getUserDownloadHistory, resources, resourcesFiltered, selectedResourceIndexes]);

    const toggleFavorite = useCallback((resourceIndex) => {
        const resource = resourcesFiltered[resourceIndex];
        const originalIndex = resources.indexOf(resource);
        const type = resource.product_download_id ? 'file' : (resource.product_line_id ? 'productLine' : 'product');
        if (type === 'file') {
            Http().post(`${API_ROOT}/product-downloads/favorite-product-download/${resource.product_download_id}`, {
                type
            }).then(({data}) => {
                setProductDownloads(current => {
                    const updated = [...current];
                    updated[originalIndex] = {
                        ...resource,
                        product_download_is_favorite: resource.product_download_is_favorite == '1' ? '0' : '1'
                    }

                    return updated;
                });
            });
        } else {
            Http().post(`${API_ROOT}/product-downloads/favorite-product/${type === 'productLine' ? resource.product_line_id : resource.product_id}`, {
                type
            }).then(({data}) => {
                setProducts(current => {
                    const updated = [...current];
                    updated.splice(resourceIndex, 1, data.productLine);

                    return updated;
                });
            });
        }
    }, [resources, resourcesFiltered]);

    const toggleFolderFavorite = useCallback(({productId, productLineId, folder}) => {
        const type = (productLineId ? 'productLine' : 'product');
        Http().post(`${API_ROOT}/product-downloads/favorite-product/${type === 'productLine' ? productLineId : productId}`, {
            type,
            folder
        }).then(() => {
            setProductDownloads(current => {
                const updated = [...current];
                for (let [index, resource] of updated.entries()) {
                    if(resource.product_line_id === productLineId && resource.product_id === productId && resource.product_product_line_product_download_folder === folder) {
                        updated[index] = {
                            ...resource,
                            product_folder_is_favorite: resource.product_folder_is_favorite == '1' ? '0' : '1',
                            product_download_is_favorite: resource.product_download_is_favorite == '1' ? '0' : '1',
                        };
                    }
                }

                return updated;
            });
        });
    }, []);

    const favoriteSelected = useCallback(() => {
        Http().post(`${API_ROOT}/product-downloads/favorite-product-downloads`, {
            productDownloadIds: selectedResourceIndexes.map(index => resourcesFiltered[index].product_download_id)
        }).then(() => {
            fetchProductDownloads({
                productId: products[selectedProductIndex]?.product_id,
                productLineId: products[selectedProductIndex]?.product_line_id,
                isFavorite: isFavorite ? '1' : '0',
                getGeneral: getGeneral ? '1' : '0'
            }).then(resources => {
                setProductDownloads(resources);
            })
        })
    }, [fetchProductDownloads, getGeneral, isFavorite, products, resources, resourcesFiltered, selectedProductIndex, selectedResourceIndexes]);

    useEffect(() => {
        fetchProducts().then(products => {
            setProducts(products);
        })
    }, [fetchProducts, setProducts]);

    useEffect(() => {
        setSelectedResourceIndexes([]);
        if (productDownloadsActive) {
            fetchProductDownloads({
                productId: products[selectedProductIndex]?.product_id,
                productLineId: products[selectedProductIndex]?.product_line_id,
                isFavorite: isFavorite ? '1' : '0',
                getGeneral: getGeneral ? '1' : '0',
                search,
                fileType,
                productDownloadIds,
            }).then(resources => {
                setProductDownloads(resources);
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    console.log('error: ', err)
                }
            })
        } else {
            setProductDownloads([]);
            setSearch('');
            setFileType('');
        }

    }, [selectedProductIndex, setProductDownloads, fetchProductDownloads, products, isFavorite, search, fileType, productDownloadsActive, productDownloadIds, getGeneral]);

    useEffect(() => {
        getUserDownloadHistory();
    }, [getUserDownloadHistory]);

    useEffect(() => {
        Http().get(`${API_ROOT}/product-downloads/get-product-downloads-history`)
            .then(({data}) => {
                setProductDownloadsHistory(data.productDownloadsHistory);
            })
    }, [])

    return (
        <Screen
            hideSidebar={!user}
            headerContent={
                <>
                    {!!user && <WelcomeHeader showQuote={false}/>}
                    <ResourceHeader
                        selectedProduct={products[selectedProductIndex]}
                        setSelectedProductIndex={setSelectedProductIndex}
                        setSelectedFolder={setSelectedFolder}
                        selectedFolder={selectedFolder}
                        search={search}
                        setSearch={setSearch}
                        fileType={fileType}
                        setFileType={setFileType}
                        fileTypes={fileTypes}
                        isFavorite={isFavorite}
                        userDownloadHistory={userDownloadHistory}
                        setProductDownloadIds={setProductDownloadIds}
                        productDownloadIds={productDownloadIds}
                        productDownloadsHistory={productDownloadsHistory}
                        getGeneral={getGeneral}
                        setGetGeneral={setGetGeneral}
                    />
                </>
            }>
            <Helmet>
                <title>Resources</title>
            </Helmet>
            <ResourceLines
                isFavorite={isFavorite}
                resources={resourcesFiltered}
                folders={folders}
                setSelectedProductIndex={setSelectedProductIndex}
                setSelectedFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
                toggleFavorite={toggleFavorite}
                onSelectDownload={(index) => {
                    setSelectedResourceIndexes(current => {
                        const updated = [...current];
                        if (updated.includes(index)) {
                            updated.splice(updated.indexOf(index), 1);
                        } else {
                            updated.push(index);
                        }

                        return updated;
                    })
                }}
                onSelectAll={() => {
                    setSelectedResourceIndexes([...Array(resourcesFiltered.length).keys()]);
                }}
                onDeselectAll={() => {
                    setSelectedResourceIndexes([]);
                }}
                selectedResourceIndexes={selectedResourceIndexes}
                productDownloadsActive={productDownloadsActive}
                favoriteSelected={() => {
                    favoriteSelected();
                }}
                generateProductDownloadArchive={generateProductDownloadArchive}
                productDownloadShareKeys={productDownloadShareKeys}
                setProductDownloadShareKeys={setProductDownloadShareKeys}
                setGetGeneral={setGetGeneral}
                getGeneral={getGeneral}
                toggleFolderFavorite={toggleFolderFavorite}
                fetchOneTimeToken={fetchOneTimeToken}
            />
        </Screen>
    );
};

export default Resources;
